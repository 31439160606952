.form {
  max-width: 25rem;
  min-width: 25rem;
}

.formGroup {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.25rem;
}

.formLabel {
  margin-bottom: 0.25rem;
}

.formField {
  padding: 0.25rem;
}

.formFieldError {
  border: 1px solid #e11d48;
}

.formFieldErrorMessage {
  color: #e11d48;
}

.formActions {
  display: flex;
  justify-content: flex-end;
}

.formSubmitBtn {
  padding: 0.25rem 0.25rem;
  min-width: 25rem;
  background-color: #9333ea;
  color: #f3e8ff;
  text-transform: uppercase;
  font-weight: 500;
}