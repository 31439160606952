.container {
  max-width: "600px";
  margin: 0 auto
}

.error {
  color: red;
}

.myForm {
  font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
  font-size: 0.8em;
  width: 30em;
  padding: 1em;
  border: 1px solid #ccc;
}

.myForm * {
  box-sizing: border-box;
}

.myForm fieldset {
  border: none;
  padding: 0;
}

.myForm legend,
.myForm label {
  padding: 0;
  font-weight: bold;
}

.myForm label.choice {
  font-size: 0.9em;
  font-weight: normal;
}

.myForm label {
  text-align: left;
  display: block;
}

.myForm input[type="text"],
.myForm input[type="tel"],
.myForm input[type="password"],
.myForm input[type="email"],
.myForm input[type="date"],
.myForm input[type="number"],
.myForm input[type="time"],
.myForm input[type="file"],
.myForm select,
.myForm textarea {
  float: right;
  width: 60%;
  border: 1px solid #ccc;
  font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
  font-size: 0.9em;
  padding: 0.3em;
}

.myForm textarea {
  height: 100px;
}

.myForm input[type="radio"],
.myForm input[type="checkbox"] {
  margin-left: 40%;
}

.myForm button {
  /* padding: 0.5em; */
  /* border-radius: 0.5em; */
  padding: 0.25rem 0.25rem;
  min-width: 60%;
  background-color: #4e65f5;
  color: #fff;
  border: none;
  font-weight: bold;
  margin-left: 40%;
  margin-top: 0.4em;
}

.myForm button:hover {
  background: #ccc;
  cursor: pointer;
}